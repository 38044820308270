
import { defineComponent } from 'vue';
import { Result } from 'ant-design-vue';

export default defineComponent({
  name: 'Exception403',
  setup() {
    return {};
  },
  components: {
    // custom register component
    [Result.name]: Result,
  },
});
